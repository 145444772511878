import React from 'react';
import type { IconProps } from '../../../../../src/features/common/types/common';

const WebsiteLinkIcon = ({ width, color }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 11 12"
      fill="none"
    >
      <g clipPath="url(#clip0_906_555)">
        <path
          d="M6.33008 11.2832C6.89711 10.2793 7.30612 9.19169 7.52921 8.05762H10.3923C9.67649 9.75872 8.14272 10.9672 6.33008 11.2832Z"
          fill={color}
        />
        <path
          d="M4.85205 10.8184C5.01937 11.1252 5.40049 11.246 5.71654 11.0787C5.82809 11.0229 5.91175 10.93 5.97682 10.8184C6.4416 9.95393 6.78554 9.01507 6.99004 8.05762H3.85742C4.06193 9.02437 4.39657 9.95393 4.87064 10.8184H4.85205Z"
          fill={color}
        />
        <path
          d="M4.48991 11.2832C2.67727 10.9672 1.1435 9.74943 0.427734 8.05762H3.29078C3.51387 9.19169 3.92288 10.2793 4.48991 11.2832Z"
          fill={color}
        />
        <path
          d="M7.62305 4.38574C7.77178 5.42686 7.77178 6.47727 7.62305 7.50908H10.5883C10.8951 6.48656 10.8951 5.39897 10.5883 4.38574H7.62305Z"
          fill={color}
        />
        <path
          d="M7.07478 7.51885C7.2328 6.48703 7.2328 5.42733 7.07478 4.39551H3.73766C3.57963 5.42733 3.57963 6.48703 3.73766 7.51885H7.07478Z"
          fill={color}
        />
        <path
          d="M3.19732 7.51885C3.04859 6.47774 3.04859 5.42733 3.19732 4.39551H0.232019C-0.0747356 5.40874 -0.0747356 6.49633 0.232019 7.51885H3.19732Z"
          fill={color}
        />
        <path
          d="M6.33008 0.621582C6.89711 1.62551 7.30612 2.71311 7.52921 3.84718H10.3923C9.67649 2.14607 8.14272 0.937635 6.33008 0.621582Z"
          fill={color}
        />
        <path
          d="M4.85111 1.08635C4.38633 1.95085 4.04239 2.88971 3.83789 3.84716H6.97051C6.766 2.88041 6.43136 1.95085 5.95729 1.08635C5.78997 0.779594 5.40885 0.658751 5.0928 0.826072C4.98125 0.881846 4.89759 0.974803 4.83252 1.08635H4.85111Z"
          fill={color}
        />
        <path
          d="M0.427734 3.84718H3.29078C3.51387 2.71311 3.92288 1.62551 4.48991 0.621582C2.67727 0.937635 1.1435 2.15537 0.427734 3.84718Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_906_555">
          <rect
            width="10.8201"
            height="10.6621"
            fill="white"
            transform="translate(0 0.621582)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WebsiteLinkIcon;
