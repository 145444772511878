import React from 'react';
import type { IconProps } from '../../../../../src/features/common/types/common';

const DownloadIcon = ({ width, color }: IconProps) => {
  return (
    <svg
      width={width}
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1" clipPath="url(#clip0_906_2062)">
        <path
          id="Vector"
          d="M5.00025 8.95259C3.58292 8.95259 2.16558 8.95227 0.748249 8.95324C0.606746 8.95324 0.472995 8.92865 0.350294 8.85794C0.187187 8.76393 0.07537 8.62914 0.0282026 8.44857C0.0113806 8.38417 0.00181516 8.31621 0.00181516 8.24971C-0.000163897 7.45492 0.000990552 6.66013 1.02431e-06 5.86534C-0.000328818 5.67878 0.0789983 5.5372 0.24293 5.44643C0.468377 5.32167 0.746105 5.40646 0.87194 5.61762C0.920426 5.699 0.938238 5.78751 0.938238 5.88039C0.938238 6.57551 0.938238 7.27062 0.938238 7.96574C0.938238 8.00921 0.959788 8.031 1.00289 8.03111C3.66719 8.03111 6.33166 8.03111 8.99596 8.03111C9.03961 8.03111 9.06149 8.00964 9.0616 7.96671C9.0616 7.2703 9.0616 6.57389 9.0616 5.87731C9.0616 5.69091 9.13532 5.54221 9.30387 5.44885C9.40859 5.39093 9.5209 5.37232 9.64097 5.40306C9.78709 5.4406 9.89132 5.5249 9.95745 5.65677C9.99044 5.72247 9.99951 5.79237 9.99934 5.86453C9.99901 6.11112 9.99934 6.35755 9.99934 6.60415C9.99934 7.15105 9.99835 7.69795 9.99984 8.24486C10.0005 8.46734 9.91721 8.65083 9.74437 8.79354C9.62431 8.89273 9.48264 8.94272 9.3263 8.95146C9.29199 8.9534 9.25769 8.95259 9.22339 8.95259C7.81562 8.95259 6.40802 8.95259 5.00025 8.95259Z"
          fill={color}
        />
        <path
          id="Vector_2"
          d="M4.52708 5.74869C4.52824 5.72798 4.53022 5.70727 4.53022 5.68656C4.53038 5.09662 4.53022 4.50651 4.53022 3.91657C4.53022 3.08942 4.53038 2.26243 4.53022 1.43528C4.53022 1.24872 4.60839 1.10552 4.77397 1.01248C4.99298 0.889345 5.26214 0.965393 5.39457 1.167C5.44949 1.2505 5.4701 1.34321 5.4701 1.44304C5.46911 2.8548 5.46944 4.26639 5.46944 5.67815C5.46944 5.70161 5.46944 5.72491 5.46944 5.74837C5.47406 5.74983 5.47868 5.75145 5.48329 5.7529C5.52073 5.71682 5.55817 5.68074 5.59544 5.64433C5.98993 5.25761 6.38311 4.86977 6.77925 4.48467C7.00337 4.26672 7.38385 4.32966 7.52568 4.60424C7.62166 4.79 7.58917 4.99808 7.43563 5.14953C7.0832 5.49741 6.72927 5.84367 6.37568 6.19042C6.0338 6.52585 5.6911 6.86046 5.3502 7.19669C5.26428 7.28132 5.16318 7.33374 5.04312 7.34345C4.90228 7.35478 4.7771 7.31093 4.6765 7.21239C4.19905 6.74558 3.7226 6.27764 3.24581 5.81018C3.02102 5.58964 2.79673 5.36861 2.57145 5.14856C2.3361 4.91863 2.39745 4.54065 2.69695 4.40085C2.86715 4.32141 3.07726 4.34584 3.21992 4.48434C3.55899 4.8133 3.89543 5.14467 4.23253 5.4754C4.32621 5.56731 4.41873 5.66051 4.51191 5.75323C4.51702 5.75177 4.52213 5.75015 4.52725 5.74869H4.52708Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_906_2062">
          <rect
            width="10"
            height="8"
            fill="white"
            transform="translate(0 0.953125)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownloadIcon;
