import React from 'react';
import type { IconProps } from '../../../../../src/features/common/types/common';

export const ViewProfileIcon = ({ width, color }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 11 10"
      fill="none"
    >
      <g clipPath="url(#clip0_906_546)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.1734 2.51298C9.17027 2.28519 10.1273 3.12104 9.96736 4.10939C10.9642 4.52763 11.1633 5.28755 10.8846 5.81969C10.5653 6.50368 9.52865 6.95989 8.1734 6.61822C5.42238 6.54229 6.13983 8.40509 6.45907 9.62122H4.46533C4.94386 7.30221 5.30292 7.53064 3.26936 6.54229C2.07338 7.03646 0 6.50433 0 5.32616C0 4.79402 0.438704 4.33781 1.07651 4.11003C0.797092 3.08372 1.83378 2.13334 2.9103 2.43769C3.70739 -0.0331726 7.45595 0.0427551 8.1734 2.51362V2.51298ZM7.53559 6.35247C7.3763 6.23858 7.21635 6.12469 7.09688 5.97219C6.9376 6.16201 6.77764 6.35247 6.65818 6.54229C6.8971 6.4284 7.21635 6.39044 7.53559 6.35247ZM6.77832 5.89626C6.33961 6.08608 5.74162 6.20062 5.22328 6.16265C5.22328 6.46701 5.30292 6.84664 5.4622 7.07507C6.10001 6.77072 6.33961 6.31515 6.77764 5.89691L6.77832 5.89626ZM4.86422 6.12404C4.66511 6.08608 4.50515 6.04812 4.34587 6.01015C4.42551 6.35247 4.5848 6.58025 4.90404 6.69415C4.86422 6.50433 4.8244 6.31386 4.86422 6.12404ZM3.94699 5.97219C3.86735 6.12405 3.74788 6.23858 3.58792 6.35247C3.74721 6.46636 3.90717 6.54229 4.14609 6.65683C4.02663 6.42904 3.94699 6.20062 3.94699 5.97283V5.97219Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_906_546">
          <rect
            width="11"
            height="9"
            fill="white"
            transform="translate(0 0.621582)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
