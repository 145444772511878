import React from 'react';
import type { IconProps } from '../../../../../src/features/common/types/common';

const DownloadReportIcon = ({ width, color }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="0 0 10 9"
      fill="none"
    >
      <g clipPath="url(#clip0_906_1657)">
        <path
          d="M5.00025 8.49935C3.58292 8.49935 2.16558 8.49902 0.748249 8.49999C0.606746 8.49999 0.472995 8.4754 0.350294 8.40469C0.187187 8.31068 0.07537 8.1759 0.0282026 7.99532C0.0113806 7.93092 0.00181516 7.86297 0.00181516 7.79646C-0.000163897 7.00167 0.000990552 6.20688 1.02431e-06 5.41209C-0.000328818 5.22553 0.0789983 5.08395 0.24293 4.99318C0.468377 4.86843 0.746105 4.95321 0.87194 5.16437C0.920426 5.24576 0.938238 5.33426 0.938238 5.42714C0.938238 6.12226 0.938238 6.81738 0.938238 7.51249C0.938238 7.55597 0.959788 7.57776 1.00289 7.57786C3.66719 7.57786 6.33166 7.57786 8.99596 7.57786C9.03961 7.57786 9.06149 7.5564 9.0616 7.51346C9.0616 6.81705 9.0616 6.12064 9.0616 5.42407C9.0616 5.23767 9.13532 5.08897 9.30387 4.99561C9.40859 4.93768 9.5209 4.91907 9.64097 4.94981C9.78709 4.98735 9.89132 5.07165 9.95745 5.20353C9.99044 5.26922 9.99951 5.33912 9.99934 5.41128C9.99901 5.65788 9.99934 5.90431 9.99934 6.1509C9.99934 6.6978 9.99835 7.24471 9.99984 7.79161C10.0005 8.01409 9.91721 8.19758 9.74437 8.34029C9.62431 8.43948 9.48264 8.48948 9.3263 8.49821C9.29199 8.50016 9.25769 8.49935 9.22339 8.49935C7.81562 8.49935 6.40802 8.49935 5.00025 8.49935Z"
          fill={color}
        />
        <path
          d="M4.52726 5.29569C4.52842 5.27498 4.5304 5.25427 4.5304 5.23356C4.53056 4.64361 4.5304 4.05351 4.5304 3.46357C4.5304 2.63641 4.53056 1.80943 4.5304 0.982274C4.5304 0.795712 4.60857 0.652514 4.77415 0.559476C4.99317 0.436342 5.26232 0.51239 5.39475 0.714C5.44967 0.797492 5.47028 0.890207 5.47028 0.990041C5.46929 2.4018 5.46962 3.81339 5.46962 5.22514C5.46962 5.24861 5.46962 5.27191 5.46962 5.29537C5.47424 5.29682 5.47886 5.29844 5.48348 5.2999C5.52092 5.26382 5.55835 5.22773 5.59562 5.19133C5.99012 4.80461 6.38329 4.41676 6.77943 4.03167C7.00356 3.81371 7.38403 3.87666 7.52586 4.15124C7.62185 4.33699 7.58936 4.54507 7.43582 4.69653C7.08338 5.04441 6.72946 5.39067 6.37587 5.73742C6.03398 6.07284 5.69128 6.40746 5.35039 6.74369C5.26446 6.82832 5.16337 6.88074 5.0433 6.89045C4.90246 6.90178 4.77729 6.85793 4.67668 6.75939C4.19924 6.29258 3.72278 5.82463 3.24599 5.35718C3.0212 5.13664 2.79691 4.91561 2.57163 4.69555C2.33629 4.46563 2.39764 4.08765 2.69713 3.94785C2.86733 3.8684 3.07744 3.89284 3.2201 4.03134C3.55918 4.36029 3.89562 4.69167 4.23272 5.0224C4.32639 5.11431 4.41891 5.20751 4.51209 5.30022C4.5172 5.29877 4.52232 5.29715 4.52743 5.29569H4.52726Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_906_1657">
          <rect
            width="10"
            height="8"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownloadReportIcon;
